<template>
    <div class="ValidationRules" style="display:inline-block;">

        <label class="label" @click="distributionLoading = true;" @keyup.enter="distributionLoading = true;"><slot></slot></label>

        <el-dialog
                :title="title"
                class="editDialog"
                :append-to-body="true"
                width="70vw"
                :visible.sync="distributionLoading"
                :close-on-click-modal="false"
                :destroy-on-close="true"
                :show-close="true"
            >
            <div class="content">
                <el-form ref="form" :model="form" label-width="0" :rules="rules" size="mini">
                    <div class="main">
                        <el-table empty-text="请添加" ref="formTable" :data="form.tableData" height="string" border  header-cell-class-name="formTableHeader">

                            <el-table-column label="正则表达式" align="center">
                                <template slot-scope="scope">
                                    <el-form-item class="form-item" :prop="'tableData[' + scope.$index + '].pattern'" :rules="rules.pattern">
                                        <el-autocomplete
                                            value-key="name"
                                            class="inline-input"
                                            v-model="scope.row.pattern"
                                            :fetch-suggestions="regularQuerySearch"
                                            placeholder="请输入内容"
                                            @select="regularHandleSelect($event,scope.$index)"
                                        ></el-autocomplete>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column label="错误提示" align="center">
                                <template slot-scope="scope">
                                    <el-form-item class="form-item" :prop="'tableData[' + scope.$index + '].message'" :rules="rules.message">
                                        <el-input  size="mini" v-model="scope.row.message" placeholder="请输入"></el-input>
                                    </el-form-item>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" width="80">
                                <el-button slot="header" type="primary" size="mini" icon="el-icon-plus" @click="add"></el-button>
                                <template slot-scope="scope">
                                    <el-button slot="header" type="danger" size="mini" icon="el-icon-delete" @click="del(scope)"></el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </el-form>
            </div>

            <div slot="footer">
                <el-button type="success" @click="save">确定</el-button>
                <el-button  type="info" plain @click="distributionLoading = false">取 消</el-button>
            </div>

        </el-dialog>
    </div>
</template>

<script>
import * as regulars from '@/util/regular'
const restaurants = []
for(let k in regulars){
    restaurants.push({
        name:regulars[k].name,
        value:`${regulars[k].value}`,
    })
}
export default {
    components: {},
    props: {
        title:{
            type:String,
            default:'验证规则'
        },
        value: {
            type:String,
            default:''
        },
    },
    data() {
        return {
            distributionLoading:false,
            form: {
                tableData:[]
            },
            rules: {
                pattern: [
                    {required: true, message:'请输入正则表达式', trigger: ['change', 'blur']}
                ],
                message: [
                    {required: true, message:'请输入错误提示', trigger: ['change', 'blur']}
                ],
            },
        };
    },
    model: {
        prop: 'value',
        event: 'change'
    },
    watch:{
        value:{
            immediate:true,//初始化立即执行
            handler: function (newVal){
                if(newVal){
                    try{
                        this.form.tableData = JSON.parse(newVal)
                    }catch (err){
                        console.log(err)
                    };
                }
            }
        },
    },
    created() {
    },
    methods: {
        regularQuerySearch(queryString, cb) {
            cb(restaurants);
        },
        regularHandleSelect(item,i) {
            this.form.tableData[i].pattern = item.value.replace(/^\/+/,'').replace(/\/+$/,'');
        },
        add(){
            this.form.tableData.push({});
        },
        del(r){
            this.form.tableData.splice(r.$index,1);
        },
        save(){
            this.$refs.form.validate((valid,obj) => {
                if (valid) {
                    this.distributionLoading = false;
                    this.$emit('change',JSON.stringify(this.form.tableData));
                }
            })
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@/styles/config.scss";
.ValidationRules {
    /deep/.label {
        * {
            cursor:pointer;
        }
    }
}
.el-form {
    display:block;
    height:100%;
    /deep/.el-form-item {
        margin:0;
        padding:0;
        &.is-error {
            position:relative;
            bottom:6px;
            .el-input__inner {
                border-color:#f00;
            }
            .el-form-item__error {

                transform: scale(1,.8);
            }
        }
        .el-form-item__content {
            line-height: 28px;
        }
    }
    /deep/.el-descriptions {
        white-space: nowrap;
        td,th {
            text-align:center;
        }
    }
    /deep/.el-table {
        height: calc(70vh - 163px);
        .formTableHeader {
            background: #fafafa!important;
        }
    }
}
</style>
